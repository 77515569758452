import React, { useEffect, useState, useRef } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Link, Box, Grid, MenuItem, Menu
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import styles from './BusinessList.module.css'; // Import the CSS module
import { useNavigate } from 'react-router-dom';
import icon from "../asset/Icon.svg"
import menu from "../asset/menu.svg"
import { useDispatch } from 'react-redux';
import { setBusinessId, resetBusinessId } from '../../features/business/businessSlice';


const BusinessList = ({showForm , setShowForm}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const [messages, setMessages] = useState([
    { text: 'Welcome! You currently do not have a business yet. What would you like to do next?', sender: 'system' },
  ]);
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await fetch('api/getAllBusiness/');
        const data = await response.json();
        setBusinesses(data); // Assuming the response is an array of businesses
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };

    fetchBusinesses();

   

  }, []);



   const handleCreateBusiness = () => {


    navigate('/landing');
    setShowForm(true)

  };

  

  const handlebusinessname = (businessId) => {
   
    // if (businessName) {
      // navigate('/landing', { state: { businessName }, setForm: true });
      // navigate('/landing');
    // }
    dispatch(setBusinessId(businessId));
    navigate('/landing',{setForm: true });
    setShowForm(true)
  };
  
  

  const handleHomePage = () => {


    navigate('/');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // Clear all localStorage items
    localStorage.clear();

    
    navigate('/'); // Change this to the correct route for your homepage
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-menu' : undefined;

  const handleAllBusiness = () => {

    // Redirect to the homepage
    navigate('/BusinessList'); // Change this to the correct route for your homepage
  };
  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Sample business data
  
  return (
    <Box className={styles.pageBackground}>
      {/* Header */}
      <box position="static" className={styles.header}>
        <Toolbar className={styles.toolbar}>
          {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
          <IconButton onClick={toggleMenu} edge="start" color="inherit" aria-label="menu">
            {/* <MenuIcon style={{ color: "#03dac5" }} /> */}
            <img src={menu} ></img>
          </IconButton>
          <Typography style={{fontWeight:"bold"}} variant="h4" onClick={handleHomePage} className={styles.logo}>
            R.
          </Typography>
          {/* </div> */}
        </Toolbar>
      </box>


      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateBusiness}>Create New Business</MenuItem>
        <MenuItem onClick={handleAllBusiness}>All Businesses</MenuItem>
        <MenuItem onClick={handleClose}>All Campaigns</MenuItem>
        <MenuItem onClick={handleClose}>All Customers</MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>

      {/* Title and Create Button */}
      <Grid container className={styles.businessTitleContainer}>
        <Grid item xs={6}>
          <Typography variant="h5" className={styles.allBusinessTitle}>
            All Businesses
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button variant="outlined" className={styles.createButton} onClick={handleCreateBusiness}>
            Create New Business
          </Button>
        </Grid>
      </Grid>

      {/* Business List */}
      <div className={styles.responsiveContainer}>
        <TableContainer className={styles.tableContainer}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>Business Name</TableCell>
                <TableCell className={styles.tableCellEnd}>Last Modified</TableCell>
                <TableCell className={styles.tableCellEnd}>Campaigns</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businesses.map((business, index) => (
                <TableRow key={index}>
                  {/* Business Name Link */}
                  <TableCell>
                    <div key={business.id} onClick={() => handlebusinessname(business.id)} className={styles.businessLink}>
                      {business.name}
                    </div>
                  </TableCell>

                 

<TableCell className={styles.tableCellEnd}>
  {(business.updated_at)} {/* Safely format and display the date */}
</TableCell>



                  {/* Campaigns */}
                  <TableCell className={styles.tableCellEnd}>
                    {business.campaigns}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default BusinessList;
