import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchBusinesses = createAsyncThunk(
    'business/fetchAll',
    async (_, thunkAPI) => {
      try {
        const response = await axios.get('api/getAllBusiness/');
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  
  export const fetchBusinessById = createAsyncThunk(
    'business/fetchById',
    async (id, thunkAPI) => {
      try {
        const response = await axios.get(`api/getBusinessByID/${id}`);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  
  export const addBusiness = createAsyncThunk(
    'business/add',
    async (businessData, thunkAPI) => {
      try {
        const response = await axios.post('api/addBusiness', businessData);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  
  export const updateBusiness = createAsyncThunk(
    'business/update',
    async ({ id, businessData }, thunkAPI) => {
      try {
        const response = await axios.put(`api/updateBusinessByID/${id}`, businessData);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  // Thunk to fetch the latest business
  export const fetchLatestBusiness = createAsyncThunk('business/fetchLatest', async (_, thunkAPI) => {
    try {
      const response = await axios.get('api/getAllBusiness/');
      if (response.status === 200) {
        const latestBusiness = response.data.reduce((prev, current) => {
          return prev.id > current.id ? prev : current;
        }, response.data[0]);
        return latestBusiness;
      }
      throw new Error('Failed to fetch businesses');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  });



const initialState = {
  businessId: null, // Store the current business ID

  businesses: [],
  currentBusiness: null,
  isEditing: false,
  loading: false,
  error: null,

};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusinessId(state, action) {
      state.businessId = action.payload;
    },
    resetBusinessId(state) {
      state.businessId = null;
    },

    resetBusinessState: (state) => {
        state.currentBusiness = null;
      state.error = null;
      state.loading = false;
      },
      openBlankForm: (state) => {
        state.currentBusiness = null;
        state.isEditing = false;
      },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = action.payload;
      })
      .addCase(fetchBusinesses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBusinessById.fulfilled, (state, action) => {
        state.currentBusiness = action.payload;
        state.isEditing = true;
      })
      .addCase(addBusiness.fulfilled, (state, action) => {
        state.businesses.push(action.payload);
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        const index = state.businesses.findIndex((b) => b.id === action.payload.id);
        if (index !== -1) {
          state.businesses[index] = action.payload;
        }
        state.currentBusiness = action.payload;
      });
  },


});

export const { setBusinessId, resetBusinessId , resetBusinessState, openBlankForm } = businessSlice.actions;
export default businessSlice.reducer;
