

export const formatBusinessProfile = (response) => {
  // Remove any prefixes like AI:, AI Assistant:, Assistant:, bot: if present before \n\n
  const prefixes = ["AI:", "AI Assistant:", "Assistant:", "bot:"];

  // Check for prefixes and remove them if they appear before \n\n
  let cleanedResponse = response;
  prefixes.forEach((prefix) => {
    const prefixIndex = response.indexOf(prefix);
    const doubleNewlineIndex = response.indexOf('\n\n');
    if (prefixIndex !== -1 && (doubleNewlineIndex === -1 || prefixIndex < doubleNewlineIndex)) {
      cleanedResponse = cleanedResponse.replace(prefix, "").trim();
    }
  });

  // Ensure there is a \n\n in the response, and only trim content after the first \n\n
  const doubleNewlineIndex = cleanedResponse.indexOf('\n\n');
  let trimmedResponse = cleanedResponse;

  if (doubleNewlineIndex !== -1) {
    trimmedResponse = cleanedResponse.substring(doubleNewlineIndex + 2).trim();
  }

  // Sanitize and format the response
  const formattedResponse = trimmedResponse
    .replace(/\n/g, '<br>') // Replace newlines with <br>
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold for `**...**`
    .replace(/\n\*\s(.*?)(?=\n|$)/g, '<br>- $1') // Replace \n* with - for list-like items
    .replace(/\•\s(.*?)(?=\n|\•|$)/g, '<li>$1</li>') // Convert bullet points to <li>
    .replace(/<li>(.*?)<\/li>/g, '<li>$1</li>') // Wrap <li> in <ul>, avoid nested <ul><ul>
    .replace(/<ul><\/ul>/g, ''); // Remove empty <ul> if any

  return formattedResponse;
};


